import * as api from "@/api";
import { openFile } from "@/file";
import globals from "@/globals";
import { saveSettings, uploadSettings } from "@/settings";
import { getSuffix, isSiteOpened, rmBackup } from "@/site";
import { SiteContent } from "@/types";
import { showDialog, showToast, sleep } from "@/utils";
import loader from "@monaco-editor/loader";
import i18next, { t } from "i18next";
import * as Monaco from "monaco-editor/esm/vs/editor/editor.api";

const codeTextArea = document.getElementById("code") as HTMLTextAreaElement;

let fontSizeTimeout: number | undefined = undefined;
let inlineDecorations: Monaco.editor.IEditorDecorationsCollection | null = null;
let inlineSuggestion: string | null = null;

export let editorInstance: Monaco.editor.IStandaloneCodeEditor | null = null;
export let monaco: typeof Monaco;

function applyAiCodeSuggestion(): void {
	if (!editorInstance || !inlineDecorations) {
		return;
	}
	const position = editorInstance.getPosition();
	if (!position) {
		return;
	}
	editorInstance.executeEdits("", [
		{
			forceMoveMarkers: true,
			range: new monaco.Range(
				position.lineNumber,
				position.column,
				position.lineNumber,
				position.column + 1,
			),
			text: inlineSuggestion,
		},
	]);
	inlineDecorations.clear();
}

export function changeEditorFontSize(save: boolean): void {
	if (editorInstance) {
		editorInstance.updateOptions({
			fontSize: globals.settings.fontSize,
		});
	} else {
		const code = document.getElementById("code") as HTMLTextAreaElement;
		code.style.fontSize = globals.settings.fontSize + "px";
	}
	if (save) {
		saveSettings();
		if (globals.privilege) {
			clearTimeout(fontSizeTimeout);
			fontSizeTimeout = window.setTimeout(() => {
				void uploadSettings(
					"fontSize",
					globals.settings.fontSize.toString(),
				);
			}, 1000);
		}
	}
}

function clearInlineSuggestion(): void {
	inlineDecorations?.clear();
	inlineSuggestion = null;
}

export function clearModels(skipDefault: boolean): void {
	const models = monaco.editor.getModels();
	for (const model of models) {
		if (skipDefault && model.uri.path === "/") {
			continue;
		}
		model.dispose();
	}
}

export function codeChanged(): void {
	clearInlineSuggestion();
	if (
		!globals.state.currentFile ||
		!globals.state.files ||
		!globals.state.sites
	) {
		return;
	}
	const currentFileContent = getValue();
	globals.state.files[globals.state.currentFile] = currentFileContent;
	const initialFileContent =
		globals.currentHostInitial[globals.state.currentFile];
	if (
		currentFileContent &&
		typeof initialFileContent === "string" &&
		currentFileContent.replace(/\s/g, "") !==
			initialFileContent.replace(/\s/g, "")
	) {
		if (!globals.state.changedFiles.includes(globals.state.currentFile)) {
			globals.state.changedFiles.push(globals.state.currentFile);
		}
		globals.state.hasUnsaved = true;
		try {
			localStorage.setItem(
				"CurrentHost",
				JSON.stringify(globals.state.files),
			);
			localStorage.setItem("CurrentHostname", globals.state.currentSite);
		} catch (error) {
			console.error(error);
		}
	} else {
		rmBackup();
	}
}

export function createModel(filename: string, content: string = ""): void {
	if (
		!editorInstance ||
		(!filename.endsWith(".ts") && !filename.endsWith(".tsx"))
	) {
		return;
	}
	const newModel = monaco.editor.createModel(
		content,
		"typescript",
		monaco.Uri.file(filename),
	);
	newModel.onDidChangeContent(codeChanged);
}

export function createModels(files: SiteContent): void {
	if (!editorInstance) {
		return;
	}
	clearModels(true);
	for (const filename in files) {
		const file = files[filename];
		if (typeof file !== "string") {
			continue;
		}
		createModel(filename, file);
	}
}

function createMonacoEditor(): void {
	const codeEditor = document.getElementById("code-editor") as HTMLDivElement;
	const defaultModel = monaco.editor.createModel(
		codeTextArea.value,
		readMode(true),
		monaco.Uri.file("/"),
	);
	defaultModel.onDidChangeContent(codeChanged);
	editorInstance = monaco.editor.create(codeEditor, {
		automaticLayout: true,
		fontSize: globals.settings.fontSize || 14,
		insertSpaces: false,
		model: defaultModel,
		scrollBeyondLastLine: false,
		theme: globals.isLight ? "vs" : "vs-dark",
		unicodeHighlight: {
			ambiguousCharacters: false,
		},
		wordWrap: "on",
	});
	const codeService = (
		editorInstance as unknown as {
			_codeEditorService: {
				openCodeEditor: (
					input: {
						resource: {
							path: string;
						};
					},
					source: unknown,
				) => Promise<unknown>;
			};
		}
	)._codeEditorService;
	if (codeService) {
		const openEditorBase = codeService.openCodeEditor.bind(codeService);
		codeService.openCodeEditor = async (
			input,
			source,
		): Promise<unknown> => {
			void openFile(input.resource.path.substring(1));
			return await openEditorBase(input, source);
		};
	}
}

export function deleteModels(filesToBeDeleted: string[]): void {
	if (!editorInstance) {
		return;
	}
	const models = monaco.editor.getModels();
	for (const model of models) {
		if (filesToBeDeleted.includes(model.uri.path.substring(1))) {
			model.dispose();
		}
	}
}

export async function enableMonacoEditor(delay = 0): Promise<void> {
	if (!globals.isMonacoSupported) {
		return;
	}
	codeTextArea.hidden = true;
	codeTextArea.removeEventListener("input", codeChanged);
	loader.config({
		paths: {
			vs: "https://registry.npmmirror.com/monaco-editor/0.51.0/files/min/vs",
		},
	});
	if (i18next.language !== "en-US") {
		loader.config({
			"vs/nls": {
				availableLanguages: {
					"*": i18next.language.toLowerCase(),
				},
			},
		});
	}
	monaco = await loader.init();
	if (delay) {
		await sleep(delay);
	}
	createMonacoEditor();
	monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
		allowImportingTsExtensions: true,
		allowNonTsExtensions: true,
		baseUrl: "file:///",
		module: monaco.languages.typescript.ModuleKind.ESNext,
		moduleResolution:
			monaco.languages.typescript.ModuleResolutionKind.NodeJs,
		target: monaco.languages.typescript.ScriptTarget.ESNext,
	});
	if (globals.state.currentSite) {
		createModels(globals.state.files);
		setModel(globals.state.currentFile);
	}
	initAiCodeSuggestion();
	globals.state.isMonacoEnabled = true;
}

export function getSelection(): string {
	if (!editorInstance) {
		return "";
	}
	const selection = editorInstance.getSelection();
	if (!selection) {
		return "";
	}
	return editorInstance.getModel()?.getValueInRange(selection) || "";
}

export function getValue(): string {
	let value = editorInstance ? editorInstance.getValue() : codeTextArea.value;
	const { SUFFIXES } = globals;
	for (const key in SUFFIXES) {
		const suffix = SUFFIXES[key as keyof typeof SUFFIXES];
		value = value.replaceAll(suffix, "{$rthSuffix}");
	}
	return value;
}

function initAiCodeSuggestion(): void {
	if (!editorInstance) {
		return;
	}
	editorInstance.addCommand(
		monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
		() => {
			if (inlineSuggestion) {
				applyAiCodeSuggestion();
			} else {
				void showDialog(t("confirmAiAutocompleteThisLine"), {
					showCancel: true,
				})
					.then(triggerAiCodeSuggestion)
					.catch(console.error);
			}
		},
	);
	editorInstance.addCommand(monaco.KeyCode.Escape, clearInlineSuggestion);
}

export function readMode(skipSetting = false): string {
	let type;
	if (globals.state.currentFile) {
		type = globals.state.currentFile.split(".").pop()?.toLowerCase();
		switch (type) {
			case "c":
			case "cpp":
			case "css":
			case "go":
			case "ini":
			case "java":
			case "json":
			case "less":
			case "lua":
			case "php":
			case "r":
			case "scss":
			case "sql":
			case "swift":
			case "vb":
			case "xml":
				break;
			case "coffee": {
				type = "coffeescript";
				break;
			}
			case "cs": {
				type = "csharp";
				break;
			}
			case "js":
			case "jsx": {
				type = "javascript";
				break;
			}
			case "m": {
				type = "objective-c";
				break;
			}
			case "md": {
				type = "markdown";
				break;
			}
			case "pas": {
				type = "pascal";
				break;
			}
			case "py": {
				type = "python";
				break;
			}
			case "rb": {
				type = "ruby";
				break;
			}
			case "rs": {
				type = "rust";
				break;
			}
			case "rth": {
				type = "json";
				break;
			}
			case "sh": {
				type = "shell";
				break;
			}
			case "svg": {
				type = "xml";
				break;
			}
			case "ts":
			case "tsx": {
				type = "typescript";
				break;
			}
			case "txt": {
				type = "plaintext";
				break;
			}
			default: {
				type = "html";
				break;
			}
		}
	} else {
		type = "markdown";
	}
	if (editorInstance && !skipSetting) {
		const model = editorInstance.getModel();
		if (model) {
			monaco.editor.setModelLanguage(model, type);
		}
	}
	return type;
}

export function reloadMonacoEditor(): void {
	if (!editorInstance) {
		return;
	}
	clearModels(false);
	editorInstance.dispose();
	editorInstance = null;
	createMonacoEditor();
}

function setModel(filename: string): void {
	if (!editorInstance) {
		return;
	}
	const models = monaco.editor.getModels();
	let modelFound = false;
	for (const model of models) {
		if (model.uri.path === "/" + filename) {
			editorInstance.setModel(model);
			modelFound = true;
			break;
		}
	}
	if (!modelFound) {
		for (const model of models) {
			if (model.uri.path === "/") {
				editorInstance.setModel(model);
				break;
			}
		}
	}
}

export function setValue(
	value = "",
	options?: {
		filename?: string;
		willScrollToBottom?: boolean;
	},
): void {
	value = value.replaceAll("{$rthSuffix}", getSuffix());
	codeTextArea.value = value;
	if (editorInstance) {
		editorInstance.setScrollPosition({
			scrollTop: options?.willScrollToBottom
				? editorInstance.getScrollHeight()
				: 0,
		});
		setModel(options?.filename || "");
		editorInstance.setValue(value);
	} else {
		codeChanged();
	}
}

function showInlineSuggestion(
	suggestion: string,
	position: Monaco.IPosition,
): void {
	if (!editorInstance) {
		return;
	}
	if (inlineDecorations) {
		inlineDecorations.clear();
	}
	inlineDecorations = editorInstance.createDecorationsCollection([
		{
			options: {
				after: {
					content: suggestion,
					inlineClassName: "inline-suggestion",
				},
			},
			range: new monaco.Range(
				position.lineNumber - 1,
				position.column,
				position.lineNumber,
				position.column,
			),
		},
	]);
}

async function triggerAiCodeSuggestion(): Promise<void> {
	try {
		if (!globals.privilege) {
			throw new Error("Premium required.");
		}
		if (!isSiteOpened()) {
			return;
		}
		if (!editorInstance) {
			throw new Error("Editor instance not found.");
		}
		const position = editorInstance.getPosition();
		if (!position) {
			throw new Error("Position not found.");
		}
		const currentContent = editorInstance.getValue();
		const codeLines = currentContent.split("\n");
		const currentLineContent = codeLines[position.lineNumber - 1].trim();
		if (!currentLineContent) {
			throw new Error("Empty line.");
		}

		globals.state.isLoadingScreenShown = true;

		const message = await api.getAiCodeSuggestion({
			content: currentContent,
			lineNumber: position.lineNumber,
		});
		if (!message) {
			throw new Error("No message.");
		}
		const messageLines = message
			.split("\n")
			.filter((line) => !line.startsWith("```"));
		inlineSuggestion = messageLines[0]
			.replace(currentLineContent, "")
			.trim();
		if (!inlineSuggestion) {
			throw new Error("No suggestion.");
		}
		showInlineSuggestion(inlineSuggestion, position);
	} catch (error) {
		if (process.env.NODE_ENV === "development") {
			console.error(error);
		}
		void showToast(t("noSuggestion"));
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
}

export function wrap(): void {
	if (!codeTextArea.classList.contains("wrap")) {
		codeTextArea.classList.add("wrap");
		editorInstance?.updateOptions({
			wordWrap: "on",
		});
		void showToast(t("wordWrapOn"));
	} else {
		codeTextArea.classList.remove("wrap");
		editorInstance?.updateOptions({
			wordWrap: "off",
		});
		void showToast(t("wordWrapOff"));
	}
}
