import * as api from "@/api";
import { setValue } from "@/code-editor";
import { importXterm } from "@/dependencies";
import { save } from "@/file";
import globals from "@/globals";
import { getDomain } from "@/site";
import { Column } from "@/types";
import { encodeData, isMainland, showDialog } from "@/utils";
import { t } from "i18next";

interface FileInfo {
	content: string;
	filename: string;
}

interface PackageJson {
	scripts?: Record<string, string>;
	dependencies?: Record<string, string>;
	devDependencies?: Record<string, string>;
}

const terminalContainer = document.getElementById("terminal-container");

export function closeTerminal(): void {
	if (!terminalContainer) {
		return;
	}
	terminalContainer.hidden = true;
	terminalContainer.innerHTML = "";
	globals.socket?.close();
	globals.socket = null;
}

export async function openTerminal(npmCmd?: string): Promise<void> {
	if (!terminalContainer || terminalContainer.innerHTML) {
		return;
	}
	const packageJson = parsePackageJson();
	globals.state.isLoadingScreenShown = true;
	globals.state.currentColumn = Column.CodeEditor;
	const domain = getDomain(globals.state.currentSite);
	let initCmd = "";
	if (
		packageJson &&
		(packageJson.dependencies || packageJson.devDependencies)
	) {
		initCmd += "npm install";
		if (isMainland()) {
			initCmd += " --registry=https://registry.npmmirror.com";
		}
		if (packageJson.scripts) {
			if (npmCmd) {
				initCmd += " && " + npmCmd;
			} else if (packageJson.scripts.dev) {
				initCmd += " && npm run dev";
			} else if (packageJson.scripts.start) {
				initCmd += " && npm start";
			}
		}
		initCmd += "\r\n";
	}
	try {
		const filesCopy = { ...globals.state.files };
		for (const key in filesCopy) {
			if (
				key.startsWith("dist/") ||
				key.endsWith("/") ||
				key.endsWith(".md.html") ||
				key.endsWith(".rth") ||
				typeof filesCopy[key] !== "string"
			) {
				delete filesCopy[key];
			}
		}
		await api.uploadFilesToTerminal(filesCopy);
	} catch (error) {
		console.error(error);
		void requireHelper();
		return;
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
	const Terminal = await importXterm();
	const terminal = new Terminal({
		rows: 16,
	});
	terminalContainer.hidden = false;
	terminal.open(terminalContainer);
	globals.socket = new WebSocket(
		globals.BACKEND_TERMINAL.replace("http", "ws") +
			domain +
			"?" +
			encodeData({
				initCmd: initCmd,
				token: globals.login.token,
				username: globals.login.username,
				watch: packageJson && "package.json",
			}),
	);
	globals.socket.addEventListener("message", (event) => {
		try {
			const message = JSON.parse(event.data as string) as {
				data: string | FileInfo[];
				type: string;
			};
			switch (message.type) {
				case "cmd": {
					if (typeof message.data === "string") {
						terminal.write(message.data);
					}
					break;
				}
				case "error": {
					if (typeof message.data === "string") {
						switch (message.data) {
							case "requireHelper": {
								void requireHelper();
								break;
							}
							default: {
								void showDialog(message.data);
								break;
							}
						}
					}
					break;
				}
				case "saveFiles": {
					if (Array.isArray(message.data)) {
						void saveFiles(message.data);
					}
					break;
				}
				default:
					break;
			}
		} catch (error) {
			console.error(error);
		}
	});
	globals.socket.addEventListener("close", () => {
		closeTerminal();
	});
	terminal.onData((data) => {
		globals.socket?.send(data);
	});
	terminal.focus();
}

export function parsePackageJson(): PackageJson | null {
	if (!globals.state.files) {
		return null;
	}
	const packageJson = globals.state.files["package.json"];
	if (typeof packageJson !== "string") {
		return null;
	}
	try {
		return JSON.parse(packageJson) as PackageJson;
	} catch (error) {
		console.error(error);
		return null;
	}
}

async function requireHelper(): Promise<void> {
	await showDialog(t("youNeedInstallAndRunHelper"), {
		showCancel: true,
	});
	window.open("https://docs.retiehe.com/host/terminal.html");
}

async function saveFiles(data: FileInfo[]): Promise<void> {
	const firstFile = data[0];
	if (!firstFile || !firstFile.content) {
		return;
	}
	const isDistArtifacts =
		data.length > 1 && firstFile.filename.startsWith("dist/");
	const folderName = isDistArtifacts ? "dist/" : "";
	if (folderName) {
		for (const key in globals.state.files) {
			if (key.startsWith(folderName)) {
				delete globals.state.files[key];
			}
		}
	}
	for (const file of data) {
		globals.state.files[file.filename] = file.content;
		if (globals.state.currentFile === file.filename) {
			setValue(file.content);
		}
		globals.state.changedFiles.push(file.filename);
	}
	globals.state.hasUnsaved = true;
	if (data.length > 1) {
		if (folderName && !globals.state.files[folderName]) {
			globals.state.files[folderName] = "";
		}
		void save();
	} else {
		await showDialog(
			t("confirmSaveChanges", {
				filename: firstFile.filename,
			}),
			{
				showCancel: true,
			},
		);
		void save({
			key: firstFile.filename,
			skipLocalCopy: true,
		});
	}
}

export function toggleTerminal(): void {
	if (!terminalContainer) {
		return;
	}
	if (terminalContainer.hidden) {
		void openTerminal();
	} else {
		closeTerminal();
	}
}
