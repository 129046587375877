<script setup lang="ts">
import { editorInstance, reloadMonacoEditor } from "@/code-editor";
import { handleSaveClick, isFileOpened } from "@/file";
import globals from "@/globals";
import { SiteContent } from "@/types";
import {
	closeAiBar,
	handleKeyboardClick,
	showDialog,
	showToast,
} from "@/utils";
import { t } from "i18next";
import { ref } from "vue";

const props = defineProps<{
	currentFile: string;
	files: SiteContent;
	hasPremium: boolean | null;
	hasUnsaved: boolean;
	isMonacoEnabled: boolean;
}>();

const isFolded = ref(false);

function reloadCodeEditor(): void {
	reloadMonacoEditor();
	void showToast(t("reloaded"));
}

function showMainMenu(): void {
	globals.state.isMainMenuShown = true;
}

function toggleAiBar(): void {
	if (globals.state.isClosingSidebar || !isFileOpened()) {
		return;
	}
	if (globals.state.isAiBarShown) {
		void closeAiBar();
		return;
	}
	if (!globals.login.username) {
		void showDialog(t("notLoggedIn"));
		return;
	}
	if (typeof props.files[props.currentFile] !== "string") {
		void showDialog(t("onlyCodeFilesSupported"));
		return;
	}
	globals.state.isAiBarShown = true;
	document.documentElement.classList.add("has-sidebar");
}

function toggleFold(): void {
	if (isFolded.value) {
		document.documentElement.classList.remove("fullscreen");
	} else {
		document.documentElement.classList.add("fullscreen");
	}
	isFolded.value = !isFolded.value;
	setTimeout(() => {
		editorInstance?.layout();
	}, 250);
}
</script>

<template>
	<div class="icon-bar">
		<div
			v-if="isMonacoEnabled"
			role="button"
			tabindex="0"
			:title="$t('reloadCodeEditor')"
			@click="reloadCodeEditor"
			@keydown="handleKeyboardClick($event, reloadCodeEditor)"
		>
			<font-awesome-icon icon="wrench" />
		</div>
		<div
			v-if="hasPremium || !globals.isTencent"
			role="button"
			tabindex="0"
			:title="$t('aiProgrammer')"
			@click="toggleAiBar"
			@keydown="handleKeyboardClick($event, toggleAiBar)"
		>
			<ai-icon />
		</div>
		<div
			class="hide-on-mobile"
			:class="{ highlight: hasUnsaved }"
			role="button"
			tabindex="0"
			:title="$t('save')"
			@click="handleSaveClick"
			@keydown="handleKeyboardClick($event, handleSaveClick)"
		>
			<font-awesome-icon icon="save" />
		</div>
		<div
			aria-haspopup="menu"
			role="button"
			tabindex="0"
			:title="$t('menu')"
			@click="showMainMenu"
			@keydown="handleKeyboardClick($event, showMainMenu)"
		>
			<font-awesome-icon icon="bars" />
		</div>
	</div>
	<div
		:aria-expanded="!isFolded"
		class="float-btn hide-on-mobile"
		role="button"
		tabindex="0"
		:title="isFolded ? $t('expand') : $t('fold')"
		@click="toggleFold"
		@keydown="handleKeyboardClick($event, toggleFold)"
	>
		<font-awesome-icon :icon="isFolded ? 'angle-right' : 'angle-left'" />
	</div>
</template>
