<script setup lang="ts">
import SSL from "@/SSL";
import * as api from "@/api";
import { wrap } from "@/code-editor";
import {
	configExists,
	fileExists,
	getValidFilename,
	handleSaveClick,
	openFile,
	save,
} from "@/file";
import globals from "@/globals";
import { showSettings } from "@/settings";
import { getDomain, saveHostSettings } from "@/site";
import { openTerminal, parsePackageJson, toggleTerminal } from "@/terminal";
import {
	LoginInfo,
	MenuItemInfo,
	PresetSaveCallback,
	SiteContent,
} from "@/types";
import {
	encodeData,
	getPremium,
	handleKeyboardClick,
	isPremium,
	logIn,
	showContextMenu,
	showDialog,
	showPrompt,
	sleep,
} from "@/utils";
import i18next, { t } from "i18next";
import md5 from "md5";
import { ref } from "vue";

interface Divider {
	divider: true;
	hideOnMobile?: boolean;
	when: boolean;
}

interface MainMenuItemInfo extends Partial<MenuItemInfo> {
	hasPopup?: "dialog" | "menu";
	hideOnMobile?: boolean;
	link?: string;
	onClick?: (event?: MouseEvent) => void | Promise<void>;
	requireSiteOpened?: boolean;
	when: boolean;
}

const props = defineProps<{
	files: SiteContent;
	login: LoginInfo;
	premiumRemaining: number;
}>();

const isClosing = ref<boolean>(false);

const menuItems: (MainMenuItemInfo | Divider)[] = [
	{
		hasPopup: "dialog",
		icon: "right-from-bracket",
		onClick: async (): Promise<void> => {
			await showDialog(t("confirmLogOut"), {
				showCancel: true,
			});
			globals.state.isLoadingScreenShown = true;
			try {
				await api.logOut();
				localStorage.clear();
				location.reload();
			} catch (error) {
				void api.handleApiError(error);
			} finally {
				globals.state.isLoadingScreenShown = false;
			}
		},
		text: t("logOut"),
		when: !!props.login?.username,
	},
	{
		divider: true,
		when: !!props.login?.username,
	},
	{
		hideOnMobile: true,
		icon: "save",
		onClick: handleSaveClick,
		requireSiteOpened: true,
		text: t("save") + " (" + (globals.isMac ? "⌘" : "Ctrl+") + "S)",
		when:
			!globals.state.currentFile ||
			typeof props.files[globals.state.currentFile] === "string",
	},
	{
		icon: "save",
		onClick: (): void => {
			if (isPremium()) {
				void save({
					callback: PresetSaveCallback.SHOW_DIALOG,
				});
			}
		},
		requireSiteOpened: true,
		text: t("saveAll"),
		when: !globals.state.currentFile?.endsWith(".md"),
	},
	{
		icon: "code",
		onClick: async (): Promise<void> => {
			const cmd = "npm run build && echo BUILD_DONE";
			if (!globals.socket) {
				void openTerminal(cmd);
				return;
			}
			globals.socket.send("\x03");
			await sleep(100);
			globals.socket.send(cmd + "\r\n");
		},
		requireSiteOpened: true,
		text: t("build"),
		when: !!parsePackageJson()?.scripts?.build,
	},
	{
		hideOnMobile: true,
		icon: "arrow-right-arrow-left",
		onClick: wrap,
		text: t("wordWrap") + " (" + (globals.isMac ? "⌥" : "Alt+") + "Z)",
		when: true,
	},
	{
		divider: true,
		when: true,
	},
	{
		icon: "fa-brands fa-github",
		onClick: (): void => {
			window.open("https://docs.retiehe.com/host/auto-deploy.html");
		},
		text: t("gitAutoDeploy"),
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "house-laptop",
		onClick: async (): Promise<void> => {
			if (!isPremium()) {
				return;
			}
			const siteContent = props.files;
			if (!configExists(siteContent)) {
				return;
			}
			const config = siteContent["settings.rth"];
			const oldName = config.home;
			let newName = await showPrompt(t("enterNewIndexPageFilename"), {
				defaultText: oldName,
			});
			newName = getValidFilename(newName);
			if (
				!newName.toLowerCase().endsWith(".php") &&
				!globals.accept.test(newName)
			) {
				void showDialog(t("thisFileTypeCannotBeHomePage"));
			} else if (
				fileExists(newName, {
					isNegative: true,
				})
			) {
				config.home = newName;
				saveHostSettings();
				void openFile(newName);
			}
		},
		requireSiteOpened: true,
		text: t("changeHomePage"),
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "shield-halved",
		onClick: async (): Promise<void> => {
			if (!getDomain(globals.state.currentSite).includes(".")) {
				void showDialog(t("forCustomDomainsOnly"));
				return;
			} else if (!isPremium()) {
				return;
			}
			await showDialog(
				t("confirmAutomaticallyIssueCertificate"),
				{
					extraButtons: ["manually"],
					showCancel: true,
				},
				{
					manually: () => {
						globals.state.isSslPopupShown = true;
					},
				},
			);
			void new SSL().createSsl();
		},
		requireSiteOpened: true,
		text: t("configureHttps"),
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "lock",
		onClick: async (): Promise<void> => {
			if (!isPremium()) {
				return;
			}
			const siteContent = props.files;
			if (!configExists(siteContent)) {
				return;
			}
			const config = siteContent["settings.rth"];
			const newPassword = await showPrompt(t("enterNewPassword"));
			if (newPassword) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				const newUsername = await showPrompt(t("enterNewUsername"), {
					defaultText: config.username,
				});
				config.password = md5(newPassword);
				if (newUsername) {
					config.username = newUsername;
				} else {
					delete config.username;
				}
			} else {
				delete config.password;
				delete config.username;
			}
			saveHostSettings();
		},
		requireSiteOpened: true,
		text: t("setPassword"),
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "ban",
		onClick: async (): Promise<void> => {
			if (!isPremium()) {
				return;
			}
			const siteContent = props.files;
			if (!configExists(siteContent)) {
				return;
			}
			const config = siteContent["settings.rth"];
			const text = await showPrompt(t("enterIpAddressesToBlock"), {
				defaultText: config.ip,
			});
			if (text) {
				config.ip = text;
			} else {
				delete config.ip;
			}
			saveHostSettings();
		},
		requireSiteOpened: true,
		text: t("limitIpAddress"),
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "ban",
		onClick: async (): Promise<void> => {
			if (!isPremium()) {
				return;
			}
			const siteContent = props.files;
			if (!configExists(siteContent)) {
				return;
			}
			const config = siteContent["settings.rth"];
			const text = await showPrompt(t("enterAllowedReferrer"), {
				defaultText: config.referrer,
			});
			if (text) {
				config.referrer = text;
			} else {
				delete config.referrer;
			}
			saveHostSettings();
		},
		requireSiteOpened: true,
		text: t("limitReferrer"),
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "power-off",
		onClick: async (): Promise<void> => {
			const siteContent = props.files;
			if (!configExists(siteContent)) {
				return;
			}
			const config = siteContent["settings.rth"];
			if (config.isDeactivated) {
				await showDialog(t("confirmActivateSite"), {
					showCancel: true,
				});
				delete config.isDeactivated;
			} else {
				await showDialog(t("confirmDeactivateSite"), {
					showCancel: true,
				});
				config.isDeactivated = true;
			}
			saveHostSettings();
		},
		requireSiteOpened: true,
		text: props.files["settings.rth"]?.isDeactivated
			? t("activateSite")
			: t("deactivateSite"),
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "terminal",
		onClick: toggleTerminal,
		requireSiteOpened: true,
		text: t("terminal"),
		when: !globals.isMobile || !!props.files["package.json"],
	},
	{
		divider: true,
		when: true,
	},
	{
		hasPopup: "dialog",
		icon: "gear",
		onClick: showSettings,
		text: t("settings"),
		when: true,
	},
	{
		hasPopup: props.login.username ? "dialog" : undefined,
		icon: "key",
		onClick: (): void => {
			if (!globals.login.username) {
				logIn();
				return;
			}
			globals.state.isApiKeysPopupShown = true;
		},
		text: t("apiKeys"),
		when: true,
	},
	{
		divider: true,
		when: true,
	},
	{
		hasPopup: "menu",
		icon: "heart",
		onClick: (event): void => {
			showContextMenu(event, [
				{
					content: {
						icon: "fa-brands fa-alipay",
						text: t("alipay"),
					},
					onClick: (): void => {
						window.open("https://assets.retiehe.com/alipay.webp");
					},
					when: true,
				},
				{
					content: {
						icon: "fa-brands fa-weixin",
						text: t("wechatPay"),
					},
					onClick: (): void => {
						window.open(
							"https://assets.retiehe.com/wechatpay.webp",
						);
					},
					when: true,
				},
				{
					content: {
						icon: "fa-brands fa-paypal",
						text: "PayPal",
					},
					onClick: (): void => {
						window.open("https://paypal.me/ShangzhenY/10USD");
					},
					when: true,
				},
			]);
		},
		text: t("donate"),
		when: !globals.isTencent && props.premiumRemaining <= 0,
	},
	{
		icon: "circle-question",
		onClick: (): void => {
			window.open(
				"https://support.retiehe.com/?" +
					encodeData({
						app: globals.APP_NAME,
					}),
			);
		},
		text: t("helpAndFeedback"),
		when: !globals.isTencent,
	},
	{
		hasPopup: "dialog",
		icon: "circle-info",
		onClick: (): void => {
			const isChinese = i18next.language.startsWith("zh");
			const retinboxUrl = isChinese
				? "https://www.retiehe.com/"
				: "https://www.retinbox.com/";
			const shangzhenUrl = isChinese
				? "https://www.yangshangzhen.com/"
				: "https://www.shangzhenyang.com/";
			void showDialog(
				t("developer") +
					t("colon") +
					'<a href="' +
					shangzhenUrl +
					'" target="_blank">' +
					t("shangzhenYang") +
					"</a><br>" +
					t("qqGroup") +
					t("colon") +
					globals.dynamicInfo.qqGroup +
					"<br>" +
					t("operator") +
					t("colon") +
					'<a href="' +
					retinboxUrl +
					'" target="_blank">' +
					t("fuzhouRetieheSoftwareCoLtd") +
					"</a><br>" +
					t("termsOfService") +
					t("colon") +
					'<a href="https://docs.retiehe.com/tos.html" target="_blank">docs.retiehe.com/tos.html</a><br>' +
					t("privacyPolicy") +
					t("colon") +
					'<a href="https://docs.retiehe.com/privacy.html" target="_blank">docs.retiehe.com/privacy.html</a>',
				{
					isHtml: true,
					title: t("rthWebHosting").toString(),
				},
			);
		},
		text: t("about"),
		when: true,
	},
	{
		divider: true,
		when: true,
	},
	{
		icon: "square-arrow-up-right",
		link: "https://www.airportal.cn/",
		text: t("airportal"),
		when: true,
	},
	{
		icon: "square-arrow-up-right",
		link: "https://intro.limestart.cn/",
		text: t("limeStartPage"),
		when: true,
	},
];

async function closeMenu(): Promise<void> {
	if (isClosing.value || !globals.state.isMainMenuShown) {
		return;
	}
	isClosing.value = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.isMainMenuShown = false;
	isClosing.value = false;
}

function handleClick(item: MainMenuItemInfo, event?: MouseEvent): void {
	void closeMenu();
	if (item.link) {
		window.open(item.link);
	} else if (globals.state.currentSite || !item.requireSiteOpened) {
		void item.onClick?.(event);
	} else if (item.requireSiteOpened) {
		void showDialog(t("notOpenedWebsite"));
	}
}

function isDivider(item: MainMenuItemInfo | Divider): item is Divider {
	return (item as Divider).divider;
}
</script>

<template>
	<focus-trap
		:active="true"
		@deactivate="closeMenu"
	>
		<div
			class="overlay"
			:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
			role="button"
			tabindex="0"
			:aria-label="$t('closeMenu')"
			@click="closeMenu"
			@keydown="handleKeyboardClick($event, closeMenu)"
		>
			<div
				class="menu"
				:class="{ 'slide-in': !isClosing, 'slide-out': isClosing }"
			>
				<div
					v-if="login.username"
					role="button"
					tabindex="0"
					@click="getPremium"
					@keydown="handleKeyboardClick($event, getPremium)"
				>
					<font-awesome-icon
						fixedWidth
						icon="circle-user"
					/>
					<div>
						{{
							login.name ||
							login.email ||
							login.phone ||
							login.username
						}}
						<p>
							{{
								premiumRemaining > 0
									? $t("premiumRemaining", {
											days: premiumRemaining,
										})
									: $t("noPremium")
							}}
						</p>
					</div>
				</div>
				<template v-for="(item, index) in menuItems">
					<div
						v-if="item.when && !isDivider(item)"
						:aria-haspopup="item.hasPopup"
						:key="item.text || index"
						:class="{ 'hide-on-mobile': item.hideOnMobile }"
						role="button"
						tabindex="0"
						@click="handleClick(item, $event)"
						@keydown="
							handleKeyboardClick($event, () => handleClick(item))
						"
					>
						<font-awesome-icon
							fixedWidth
							:icon="item.icon"
						/>
						<span>{{ item.text }}</span>
					</div>
					<hr
						v-if="item.when && isDivider(item)"
						:key="index"
						:class="{ 'hide-on-mobile': item.hideOnMobile }"
					/>
				</template>
			</div>
		</div>
	</focus-trap>
</template>

<style scoped>
.menu {
	background-color: var(--bg-color);
	border: 1px solid var(--fg-alpha-1);
	border-radius: 10px;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
	max-height: calc(100% - 69px);
	min-width: 240px;
	overflow: auto;
	padding: 5px;
	position: fixed;
	right: 10px;
	top: 59px;
	transition: 0.25s;
}

.menu > div {
	align-items: center;
	border-radius: 5px;
	display: flex;
	line-height: 24px;
	overflow: hidden;
	padding: 5px 10px;
	text-overflow: ellipsis;
	transition: 0.25s;
	white-space: nowrap;
}

.menu div p {
	color: var(--text-theme-color);
	font-size: 14px;
	margin: 0;
}

.menu div svg {
	color: var(--fg-alpha-7);
	margin-right: 10px;
}
</style>
